export const RpDelta = [
  {
    "id": 1,
    "nome": "CARINE BENDON LEITE",
    "matricula": "304050403",
    "porcentagem": 0.6420817792411896,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/CARINE%20BENDON%20LEITE.png"
  },
  {
    "id": 2,
    "nome": "MICHEL DIAS BEZERRA",
    "matricula": "305040401",
    "porcentagem": 0.5803688504841888,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/MICHEL%20DIAS%20BEZERRA.png"
  },
  {
    "id": 3,
    "nome": "NATALIA COSTA PAIVA DA SILVA",
    "matricula": "304040401",
    "porcentagem": 0.5682843946795736,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/NATALIA%20COSTA%20PAIVA%20DA%20SILVA.png"
  },
  {
    "id": 4,
    "nome": "ERIC MARSHALL ROCHA",
    "matricula": "303040404",
    "porcentagem": 0.5626154224474037,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ERIC%20MARSHALL%20ROCHA.png"
  },
  {
    "id": 5,
    "nome": "DIEGO ANTONIO STRAPAZON",
    "matricula": "301030405",
    "porcentagem": 0.5490277551410354,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/DIEGO%20ANTONIO%20STRAPAZON%20.png"
  },
  {
    "id": 6,
    "nome": "RODRIGO DE SOUZA RIBEIRO",
    "matricula": "302010405",
    "porcentagem": 0.5398881177796987,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/RODRIGO%20DE%20SOUZA%20RIBEIRO.png"
  },
  {
    "id": 7,
    "nome": "LORRAYNE DE SOUSA SOTERO",
    "matricula": "304050401",
    "porcentagem": 0.5376748071225544,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/LORRAYNE%20D.%20S.%20SOTERO.png"
  },
  {
    "id": 8,
    "nome": "CLELIA FRANCIELE POSSOBON BESSANI",
    "matricula": "303060404",
    "porcentagem": 0.5292586470152575,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/CLELIA%20F.%20P.%20BESSANI.png"
  },
  {
    "id": 9,
    "nome": "MARCELINO NEI JUNKES",
    "matricula": "301070401",
    "porcentagem": 0.5208037021833739,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/MARCELINO%20NEI%20JUNKES.png"
  },
  {
    "id": 10,
    "nome": "ANA PAULA DA FONSECA BARBOSA",
    "matricula": "304050402",
    "porcentagem": 0.5189653600802195,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/Ana%20Paula%20Da%20Fonseca%20Barbosa.png"
  },
  {
    "id": 11,
    "nome": "PATRICK SULLIVAN SILVA AIRES",
    "matricula": "303040403",
    "porcentagem": 0.5153700213095891,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/PATRICK%20SULLIVAN%20SILVA%20AIRES%20.png"
  },
  {
    "id": 12,
    "nome": "THAISSA ALVES LAGO",
    "matricula": "304040402",
    "porcentagem": 0.5035964155326831,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/THAISSA%20ALVES%20LAGO.png"
  },
  {
    "id": 13,
    "nome": "JAILSON FAUSTINO PEREIRA",
    "matricula": "303060401",
    "porcentagem": 0.5035621059977152,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/JAILSON%20F.%20PEREIRA.png"
  },
  {
    "id": 14,
    "nome": "JEFFERSSON RODRIGUES DE OLIVEIRA",
    "matricula": "303060403",
    "porcentagem": 0.4899153267801414,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/Jefferson-Rodrigues-de-Oliveira.png"
  },
  {
    "id": 15,
    "nome": "FERNANDO COLARES COSTA BILATO",
    "matricula": "302090401",
    "porcentagem": 0.4875091735384902,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FERNANDO%20C.%20COSTA%20BILATO.png"
  },
  {
    "id": 16,
    "nome": "FABIANA ANDREA STEVANATTO PIRES",
    "matricula": "302080402",
    "porcentagem": 0.48613415587673253,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FABIANA%20ANDREA%20S.%20PIRES.png"
  },
  {
    "id": 17,
    "nome": "MATIZA ODORICIO FREIRE",
    "matricula": "301070402",
    "porcentagem": 0.4851732217496013,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/MATIZA%20O.%20FREIRE.png"
  },
  {
    "id": 18,
    "nome": "APARECIDA CAVALCANTE SALES",
    "matricula": "302020404",
    "porcentagem": 0.4815441215685618,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/APARECIDA%20C.%20SALES.png"
  },
  {
    "id": 19,
    "nome": "GILLES PEREIRA TELES",
    "matricula": "303060402",
    "porcentagem": 0.47312209149464807,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/GILLES%20PEREIRA%20TELES.png"
  },
  {
    "id": 20,
    "nome": "MARIELEN CRISTINA DELALATA",
    "matricula": "303010404",
    "porcentagem": 0.47008312838519484,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/MARIELEN%20C.%20DELALATA.png"
  },
  {
    "id": 21,
    "nome": "ESTHERFANE TEIXEIRA DE OLIVEIRA",
    "matricula": "305040402",
    "porcentagem": 0.4664788713463289,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ESTHERFANE%20T.%20DE%20OLIVEIRA.png"
  },
  {
    "id": 22,
    "nome": "LUCIVANIA PEREIRA ANTUNES",
    "matricula": "303050404",
    "porcentagem": 0.4653583940043809,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/LUCIVANIA%20P.%20ANTUNES.png"
  },
  {
    "id": 23,
    "nome": "FABIO FELISMINO PEREIRA",
    "matricula": "302060401",
    "porcentagem": 0.4651133952937905,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FABIO%20FELISMINO%20PEREIRA%20.png"
  },
  {
    "id": 24,
    "nome": "SARA GEANEZE",
    "matricula": "302020405",
    "porcentagem": 0.46323195306856646,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/SARA%20GEANEZE.png"
  },
  {
    "id": 25,
    "nome": "TERESA CRISTINA MOREIRA TORRAO",
    "matricula": "304070401",
    "porcentagem": 0.46293985336478116,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/TERESA%20C.%20M.%20TORRAO.png"
  },
  {
    "id": 26,
    "nome": "GUILHERME SCARAZZATE",
    "matricula": "303030402",
    "porcentagem": 0.4621222163321761,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/GUILHERME%20SCARAZZATE.png"
  },
  {
    "id": 27,
    "nome": "VIVIANE MATIAS DE CARVALHO",
    "matricula": "303050401",
    "porcentagem": 0.4598573873570501,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/VIVIANE%20MATIAS%20DE%20CARVALHO%20.png"
  },
  {
    "id": 28,
    "nome": "FERNANDO DA CRUZ GOUVEA NETO",
    "matricula": "304080402",
    "porcentagem": 0.4563256033850073,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FERNANDO%20D.%20C.%20GOUVEA%20NETO.png"
  },
  {
    "id": 29,
    "nome": "THAIS MARTINS BOAVENTURA",
    "matricula": "303090404",
    "porcentagem": 0.45261364558577855,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/THAIS%20M.%20BOAVENTURA%20.png"
  },
  {
    "id": 30,
    "nome": "BRUNA A GABRIELA SANTOS TEIXEIRA",
    "matricula": "304050404",
    "porcentagem": 0.4514071374734929,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/BRUNA%20A%20G.%20S.%20TEIXEIRA.png"
  },
  {
    "id": 31,
    "nome": "ALLEC ROBSON CONTREIRAS DE OLIVEIRA",
    "matricula": "303040402",
    "porcentagem": 0.44991156589195186,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ALLEC%20ROBSON%20C.%20DE%20OLIVEIRA.png"
  },
  {
    "id": 32,
    "nome": "ALEX MARCELO DA SILVA",
    "matricula": "302040404",
    "porcentagem": 0.4495967956691035,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ALEX%20MARCELO%20DA%20SILVA.png"
  },
  {
    "id": 33,
    "nome": "RAFAEL MOYSES CASA NOVA",
    "matricula": "304080403",
    "porcentagem": 0.4443626816311259,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/RAFAEL%20M.%20CASA%20NOVA.png"
  },
  {
    "id": 34,
    "nome": "FERNANDA DA SILVA KONZEN",
    "matricula": "301040403",
    "porcentagem": 0.44358372431736776,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FERNANDA%20DA%20SILVA%20KONZEN.png"
  },
  {
    "id": 35,
    "nome": "HENRIQUE MARCONDES SUAVE",
    "matricula": "302060402",
    "porcentagem": 0.43758520311679117,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/HENRIQUE%20M.%20SUAVE.png"
  },
  {
    "id": 36,
    "nome": "LUIS FERNANDO RESENDE",
    "matricula": "301030404",
    "porcentagem": 0.43320100454331256,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/LUIS%20FERNANDO%20RESENDE.png"
  },
  {
    "id": 37,
    "nome": "ARCELINO NETO SALES MARQUES",
    "matricula": "305030404",
    "porcentagem": 0.43100280689612863,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ARCELINO%20NETO%20SALES%20MARQUES.png"
  },
  {
    "id": 38,
    "nome": "FABIANA MICHALIZEN",
    "matricula": "301060405",
    "porcentagem": 0.4275697901379263,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FABIANA%20MICHALIZEN.png"
  },
  {
    "id": 39,
    "nome": "PATRIK JOSE CAVALLI",
    "matricula": "301010403",
    "porcentagem": 0.4274556604223552,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/PATRIK%20JOSE%20CAVALLI.png"
  },
  {
    "id": 40,
    "nome": "PAMELLA MARQUES BERTONI",
    "matricula": "302060403",
    "porcentagem": 0.42667594313864904,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/PAMELLA%20M.%20BERTONI%20.png"
  },
  {
    "id": 41,
    "nome": "ANDERSON  VARGAS DE MEDEIROS",
    "matricula": "301040404",
    "porcentagem": 0.42662292224333986,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ANDERSON%20%20V.%20DE%20MEDEIROS.png"
  },
  {
    "id": 42,
    "nome": "MARCO ALEXANDRE ALVES",
    "matricula": "302080401",
    "porcentagem": 0.4248062708890867,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/MARCO%20ALEXANDRE%20ALVES.png"
  },
  {
    "id": 43,
    "nome": "JEFERSON LUIZ RIBEIRO",
    "matricula": "302080404",
    "porcentagem": 0.42421488197604523,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/JEFERSON%20LUIZ%20RIBEIRO.png"
  },
  {
    "id": 44,
    "nome": "ANA GABRIELLA RIBEIRO SAMPAIO",
    "matricula": "305010401",
    "porcentagem": 0.42313064001746253,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ANA%20GABRIELLA%20RIBEIRO%20SAMPAIO.png"
  },
  {
    "id": 45,
    "nome": "SIMONE LAISE NEVES",
    "matricula": "303020404",
    "porcentagem": 0.4221072787002837,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/SIMONE%20LAISE%20NEVES.png"
  },
  {
    "id": 46,
    "nome": "CAMILA PINZON ANDRETTA",
    "matricula": "301060402",
    "porcentagem": 0.42114519567830705,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/CAMILA%20PINZON%20ANDRETTA.png"
  },
  {
    "id": 47,
    "nome": "LUIZ EDUARDO EUSTAQUIO PINTO",
    "matricula": "303080401",
    "porcentagem": 0.415121309255248,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/LUIZ%20EDUARDO%20EUSTAQUIO%20PINTO.png"
  },
  {
    "id": 48,
    "nome": "MEIRE JEANI ARAUJO FERREIRA PANTOJA",
    "matricula": "305040404",
    "porcentagem": 0.41430491684945714,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/MEIRE%20JEANI%20A.%20F.%20PANTOJA.png"
  },
  {
    "id": 49,
    "nome": "FRANCISCO LEONARDO FAUSTINO FILHO",
    "matricula": "305010402",
    "porcentagem": 0.41194267493578673,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FRANCISCO%20L.%20F.%20FILHO.png"
  },
  {
    "id": 50,
    "nome": "CARLOS CESAR MORAES BESSA",
    "matricula": "304060404",
    "porcentagem": 0.41133429963608803,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/CARLOS%20CESAR%20MORAES%20BESSA.png"
  },
  {
    "id": 51,
    "nome": "GLEIDSON NORONHA DOS SANTOS",
    "matricula": "302010403",
    "porcentagem": 0.41100758494170303,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/GLEIDSON%20NORONHA%20DOS%20SANTOS.png"
  },
  {
    "id": 52,
    "nome": "FERNANDO RODRIGUES",
    "matricula": "302040405",
    "porcentagem": 0.41098572527756205,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/FERNANDO%20RODRIGUES.png"
  },
  {
    "id": 53,
    "nome": "WARLEY DA SILVA BATISTA",
    "matricula": "304040403",
    "porcentagem": 0.410405241066969,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/WARLEY%20DA%20SILVA%20BATISTA.png"
  },
  {
    "id": 54,
    "nome": "ESTEFANIE DANDARA COSTA MONREAL",
    "matricula": "302030401",
    "porcentagem": 0.41008629492459614,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ESTEFANIE%20DANDARA%20COSTA%20MONREAL.png"
  },
  {
    "id": 55,
    "nome": "PAULO FRANCELINO DA SILVA SOBRINHO",
    "matricula": "303070403",
    "porcentagem": 0.40952227233335436,
    "img": ""
  },
  {
    "id": 56,
    "nome": "JAIME LUIS SCHLOGL MOZER",
    "matricula": "301080403",
    "porcentagem": 0.40927594581746296,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/JAIME%20LUIS%20SCHLOGL%20MOZER.png"
  },
  {
    "id": 57,
    "nome": "JESSICA FERREIRA",
    "matricula": "303020402",
    "porcentagem": 0.40741250046928007,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/JESSICA%20FERREIRA.png"
  },
  {
    "id": 58,
    "nome": "GISELE CUNHA MENDES PEREIRA",
    "matricula": "302080403",
    "porcentagem": 0.40676698455550137,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/GISELE%20CUNHA%20MENDES%20PEREIRA.png"
  },
  {
    "id": 59,
    "nome": "THIAGO FERREIRA JANSEN",
    "matricula": "305030403",
    "porcentagem": 0.40665796229335455,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/THIAGO%20F.%20JANSEN.png"
  },
  {
    "id": 60,
    "nome": "EDUARDO CARLOS ALVARENGA",
    "matricula": "303080404",
    "porcentagem": 0.4023665483898785,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/EDUARDO%20CARLOS%20ALVARENGA.png"
  },
  {
    "id": 61,
    "nome": "LUCIANO DONISETE FERNANDES FELICIANO",
    "matricula": "302090402",
    "porcentagem": 0.4010214885319784,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/LUCIANO%20DONISETE%20FERNANDES%20FELICIANO.png"
  },
  {
    "id": 62,
    "nome": "ANA PAULA SILVA FRAGA GARCIA",
    "matricula": "301050402",
    "porcentagem": 0.39998621788619354,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/ANA%20PAULA%20SILVA%20FRAGA%20GARCIA.png"
  },
  {
    "id": 63,
    "nome": "CAIO JULIO DE SOUSA TELES",
    "matricula": "303070402",
    "porcentagem": 0.3999397252413613,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-delta/CAIO%20JULIO%20DE%20SOUSA%20TELES.png"
  }
 ]

