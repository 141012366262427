export const RpAlfa =
  [
    {
      "id": 1,
      "nome": "LEONARDO AUGUSTO DE SOUZA MATTOS",
      "matricula": "304050103",
      "porcentagem": 0.6420817792411896,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20LEONARDO_MATTOS.png"
    },
    {
      "id": 2,
      "nome": "ANNE CAROLINE PADINHA REGIS RODRIGUES",
      "matricula": "305040101",
      "porcentagem": 0.5803688504841888,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ANNE.png"
    },
    {
      "id": 3,
      "nome": "LUCIANA MEDEIROS KNUPP",
      "matricula": "304040101",
      "porcentagem": 0.5682843946795736,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20LUCIANA.png"
    },
    {
      "id": 4,
      "nome": "JOAO BATISTA FERREIRA",
      "matricula": "303040104",
      "porcentagem": 0.5626154224474037,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JOAO.png"
    },
    {
      "id": 5,
      "nome": "NILTON CASTELAR PINHEIRO KLEIN",
      "matricula": "301030105",
      "porcentagem": 0.5490277551410354,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20NILTON.png"
    },
    {
      "id": 6,
      "nome": "ALESSANDRO REIS DE ALMEIDA",
      "matricula": "302010105",
      "porcentagem": 0.5398881177796987,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ALESSANDRO.png"
    },
    {
      "id": 7,
      "nome": "CARLOS AUGUSTO OLIVEIRA MUNIZ",
      "matricula": "304050101",
      "porcentagem": 0.5376748071225544,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20CARLOS.png"
    },
    {
      "id": 8,
      "nome": "JONATHAN COSTA SENA",
      "matricula": "303060104",
      "porcentagem": 0.5292586470152575,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JONATHAN_COSTA.png"
    },
    {
      "id": 9,
      "nome": "JOSE CARLOS AMARAL",
      "matricula": "301070101",
      "porcentagem": 0.5208037021833739,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JOSE_CARLOS.png"
    },
    {
      "id": 10,
      "nome": "WATHYDIR CERQUEIRA COSTA",
      "matricula": "304050102",
      "porcentagem": 0.5189653600802195,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20WATHYDIR.png"
    },
    {
      "id": 11,
      "nome": "JANAINA LISBOA FERREIRA SILVA",
      "matricula": "303040103",
      "porcentagem": 0.5153700213095891,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/JANAINA%20LISBOA%20FERREIRA%20SILVA.png"
    },
    {
      "id": 12,
      "nome": "CARLA DE OLIVEIRA BARBATO",
      "matricula": "304040102",
      "porcentagem": 0.5035964155326831,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20CARLA.png"
    },
    {
      "id": 13,
      "nome": "MONIQUE SOARES IUASSE FERNANDES",
      "matricula": "303060101",
      "porcentagem": 0.5035621059977152,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20MONIQUE.png"
    },
    {
      "id": 14,
      "nome": "EDINEY ALVES DA SILVA",
      "matricula": "303060103",
      "porcentagem": 0.4899153267801414,
      "img": ""
    },
    {
      "id": 15,
      "nome": "ROBERTO PACHECO GARCIA",
      "matricula": "302090101",
      "porcentagem": 0.4875091735384902,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ROBERTO.png"
    },
    {
      "id": 16,
      "nome": "JESSICA MENDONCA DE ARAUJO REZENDE",
      "matricula": "302080102",
      "porcentagem": 0.48613415587673253,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JESSICA.png"
    },
    {
      "id": 17,
      "nome": "PAULO CEZAR BONFIN",
      "matricula": "301070102",
      "porcentagem": 0.4851732217496013,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20PAULO_CESAR.png"
    },
    {
      "id": 18,
      "nome": "MAYRA THERESSA LEMOS  MARTINEZ",
      "matricula": "302020104",
      "porcentagem": 0.4815441215685618,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20MAYRA.png"
    },
    {
      "id": 19,
      "nome": "FELLIPE KOCH SANTOS",
      "matricula": "303060102",
      "porcentagem": 0.47312209149464807,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20FELLIPE.png"
    },
    {
      "id": 20,
      "nome": "ROGGER SILVEIRA DESSANTI",
      "matricula": "303010104",
      "porcentagem": 0.47008312838519484,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ROGGER.png"
    },
    {
      "id": 21,
      "nome": "HENRIQUE BRENDO DA SILVA OLIVEIRA",
      "matricula": "305040102",
      "porcentagem": 0.4664788713463289,
      "img": "https://www.oscaraddera.com.br/assets/images/sem-foto.png"
    },
    {
      "id": 22,
      "nome": "FABIO CASTELLUBER LUSTOSA",
      "matricula": "303050104",
      "porcentagem": 0.4653583940043809,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20FABIO.png"
    },
    {
      "id": 23,
      "nome": "LEONARDO ALECRIM DE SOUZA JUNIOR",
      "matricula": "302060101",
      "porcentagem": 0.4651133952937905,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20LEONARDO_SOUZA.png"
    },
    {
      "id": 24,
      "nome": "LUIZ DE CASTRO DO AMARAL SILVA",
      "matricula": "302020105",
      "porcentagem": 0.46323195306856646,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20LUIZ_AMARAL.png"
    },
    {
      "id": 25,
      "nome": "MICHELE FOGACA TAVARES PEREIRA",
      "matricula": "304070101",
      "porcentagem": 0.46293985336478116,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20MICHELE.png"
    },
    {
      "id": 26,
      "nome": "PAULO ADRIANO MARANGONI",
      "matricula": "303030102",
      "porcentagem": 0.4621222163321761,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20PAULO_ADRIANO.png"
    },
    {
      "id": 27,
      "nome": "GUILHERME XAVIER MANSO",
      "matricula": "303050101",
      "porcentagem": 0.4598573873570501,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20GUILHERME.png"
    },
    {
      "id": 28,
      "nome": "JULIANA CONCEICAO LOPES",
      "matricula": "304080102",
      "porcentagem": 0.4563256033850073,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JULIANA_LOPES.png"
    },
    {
      "id": 29,
      "nome": "RICARDO MATTE VALIM",
      "matricula": "303090104",
      "porcentagem": 0.45261364558577855,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20RICARDO.png"
    },
    {
      "id": 30,
      "nome": "JOEL GABRIEL GERALDO FRANKLIN",
      "matricula": "304050104",
      "porcentagem": 0.4514071374734929,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JOEL.png"
    },
    {
      "id": 31,
      "nome": "ERICA DENISE FERNANDES FIUZA MUNIZ",
      "matricula": "303040102",
      "porcentagem": 0.44991156589195186,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ERICA.png"
    },
    {
      "id": 32,
      "nome": "PRISCILLA TERTO SILVA SOUSA",
      "matricula": "302040104",
      "porcentagem": 0.4495967956691035,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20PRISCILLA.png"
    },
    {
      "id": 34,
      "nome": "THAYANE DA SILVA FERNANDES DE MORAES",
      "matricula": "304080103",
      "porcentagem": 0.4443626816311259,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/THAYANE%20DA%20SILVA%20F.%20DE%20MORAES.png"
    },
    {
      "id": 35,
      "nome": "PAULO RODRIGO SIZNANDES",
      "matricula": "301040103",
      "porcentagem": 0.44358372431736776,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20RODRIGO.png"
    },
    {
      "id": 36,
      "nome": "CAROLINA DE GODOY",
      "matricula": "302060102",
      "porcentagem": 0.43758520311679117,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20CAROLINA.png"
    },
    {
      "id": 37,
      "nome": "FERNANDO JURIATI",
      "matricula": "301030104",
      "porcentagem": 0.43320100454331256,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20FERNANDO.png"
    },
    {
      "id": 38,
      "nome": "JACKSUEC SOARES DE ARRUDA",
      "matricula": "305030104",
      "porcentagem": 0.43100280689612863,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JACKSUEC.png"
    },
    {
      "id": 39,
      "nome": "ALINE SARTORI",
      "matricula": "301060105",
      "porcentagem": 0.4275697901379263,
      "img": ""
    },
    {
      "id": 40,
      "nome": "TAINAM CAROLINA VIDAL LOURENCO",
      "matricula": "301010103",
      "porcentagem": 0.4274556604223552,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20TAINAM.png"
    },
    {
      "id": 41,
      "nome": "BRUNO CAETANO DURANTE",
      "matricula": "302060103",
      "porcentagem": 0.42667594313864904,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20BRUNO.png"
    },
    {
      "id": 42,
      "nome": "MARCIO ELENO PALHARINI",
      "matricula": "301040104",
      "porcentagem": 0.42662292224333986,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20MARCIO_ELENO.png"
    },
    {
      "id": 43,
      "nome": "AMANDA GONCALVES ABRAHAO DA SILVA",
      "matricula": "302080101",
      "porcentagem": 0.4248062708890867,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20AMANDA.png"
    },
    {
      "id": 44,
      "nome": "VANESSA FOLEGATTI CARVALHAL",
      "matricula": "302080104",
      "porcentagem": 0.42421488197604523,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20VANESSA.png"
    },
    {
      "id": 45,
      "nome": "JONATHAN LELIS DE FREITAS",
      "matricula": "305010101",
      "porcentagem": 0.42313064001746253,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20JONATHAN_LELIS.png"
    },
    {
      "id": 46,
      "nome": "FELLIPE CAMPOS REZENDE",
      "matricula": "303020104",
      "porcentagem": 0.4221072787002837,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/FELLIPE%20CAMPOS%20REZENDE.png"
    },
    {
      "id": 47,
      "nome": "MARIA JULIANA DE CARVALHO BERTELLI",
      "matricula": "301060102",
      "porcentagem": 0.42114519567830705,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/MARIA%20JULIANA%20DE%20CARVALHO%20BERTELLI.png"
    },
    {
      "id": 48,
      "nome": "VAGO",
      "matricula": "303080101",
      "porcentagem": 0.415121309255248,
      "img": ""
    },
    {
      "id": 49,
      "nome": "THIAGO SOAVE SCABIO",
      "matricula": "305040104",
      "porcentagem": 0.41430491684945714,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/THIAGO%20SOAVE%20SCABIO.png"
    },
    {
      "id": 50,
      "nome": "VICTOR GOMES MELO",
      "matricula": "305010102",
      "porcentagem": 0.41194267493578673,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20VICTOR.png"
    },
    {
      "id": 51,
      "nome": "THIAGO DA SILVA PINTO",
      "matricula": "304060104",
      "porcentagem": 0.41133429963608803,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/THIAGO%20DA%20SILVA%20PINTO.png"
    },
    {
      "id": 52,
      "nome": "VANESSA LUZ IVANKOVICH",
      "matricula": "302010103",
      "porcentagem": 0.41100758494170303,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/VANESSA%20LUZ%20IVANKOVICH.png"
    },
    {
      "id": 53,
      "nome": "ROBSON MARQUES MOREIRA",
      "matricula": "302040105",
      "porcentagem": 0.41098572527756205,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ROBSON.png"
    },
    {
      "id": 54,
      "nome": "KAREN CRISTINA MARQUES GONCALVES",
      "matricula": "304040103",
      "porcentagem": 0.410405241066969,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/Karen-Cristina-Marques-Goncalves.png"
    },
    {
      "id": 56,
      "nome": "GUILHERME VENTURA RONDANIN",
      "matricula": "302030101",
      "porcentagem": 0.41008629492459614,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/GUILHERME%20VENTURA%20RONDANIN.png"
    },
    {
      "id": 57,
      "nome": "JACQUELINE RODRIGUES DE OLIVEIRA FIGUEIREDO",
      "matricula": "303070103",
      "porcentagem": 0.40952227233335436,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/JACQUELINE%20RODRIGUES%20DE%20OLIVEIRA%20FIGUEIREDO.png"
    },
    {
      "id": 58,
      "nome": "ORIOVALDO JESUS PINTO",
      "matricula": "301080103",
      "porcentagem": 0.40927594581746296,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20ORIOVALDO.png"
    },
    {
      "id": 59,
      "nome": "DANILO KENNEDY MARTINS",
      "matricula": "303020102",
      "porcentagem": 0.40741250046928007,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/Danilo-Kennedy-Martins.png"
    },
    {
      "id": 60,
      "nome": "LIZANDRI DE OLIVEIRA RANGAN TEIXEIRA",
      "matricula": "302080103",
      "porcentagem": 0.40676698455550137,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20LIZANDRI.png"
    },
    {
      "id": 61,
      "nome": "MARCELA COLARES CORREA DE MENEZES",
      "matricula": "305030103",
      "porcentagem": 0.40665796229335455,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/REP_ALFA_%20MARCELA.png"
    },
    {
      "id": 62,
      "nome": "DIEGO ZUMERLE CANDIDO",
      "matricula": "303080104",
      "porcentagem": 0.4023665483898785,
      "img": "https://www.oscaraddera.com.br/assets/images/rp-alfa/DIEGO%20ZUMERLE%20CANDIDO.png"
    },
    {
      "id": 63,
      "nome": "SANDRO HENRIQUE COKELI",
      "matricula": "302090102",
      "porcentagem": 0.4010214885319784,
      "img": ""
    },
    {
      "id": 64,
      "nome": "KEIVID ANDER PEREIRA MUNIZ",
      "matricula": "301050102",
      "porcentagem": 0.39998621788619354,
      "img": ""
    },
    {
      "id": 65,
      "nome": "ANDERSON OLIVEIRA VERAS",
      "matricula": "303070102",
      "porcentagem": 0.3999397252413613,
      "img": ""
    }
   ]
