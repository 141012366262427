import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boas-praticas',
  templateUrl: './boas-praticas.component.html',
  styleUrls: ['./boas-praticas.component.css']
})
export class BoasPraticasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
