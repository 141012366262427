export const RpSigma = [
  {
    "id": 1,
    "nome": "MAURO VALPASSOS RIBEIRO",
    "matricula": "502050903",
    "porcentagem": 0.5773111130751236,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/MAURO%20V.%20RIBEIRO.png"
  },
  {
    "id": 2,
    "nome": "CESAR LUIZ FOGACA DA COSTA",
    "matricula": "501010907",
    "porcentagem": 0.5301571100988002,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/CESAR%20LUIZ%20F.%20DA%20COSTA.png"
  },
  {
    "id": 3,
    "nome": "MARLOM EDUARDO APONE",
    "matricula": "501080905",
    "porcentagem": 0.5234909508800286,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/MARLOM%20EDUARDO%20APONE.png"
  },
  {
    "id": 4,
    "nome": "GUSTAVO JOSE NOGUEIRA CHAVES SOUSA",
    "matricula": "502050902",
    "porcentagem": 0.5070297684736464,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/GUSTAVO%20JOSE%20N.%20C.%20SOUSA.png"
  },
  {
    "id": 5,
    "nome": "YAN CESARE YANAI BALDUINO",
    "matricula": "501030905",
    "porcentagem": 0.49049093524132686,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/YAN%20CESARE%20YANAI%20BALDUINO.png"
  },
  {
    "id": 6,
    "nome": "ADNA SILVA DOS SANTOS",
    "matricula": "501070901",
    "porcentagem": 0.49048484732356334,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/ADNA%20SILVA%20DOS%20SANTOS.png"
  },
  {
    "id": 7,
    "nome": "CARLOS ALBERTO FRIGOTTO",
    "matricula": "501030906",
    "porcentagem": 0.4851732217496013,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/Carlos-Alberto-Frigotto.png"
  },
  {
    "id": 8,
    "nome": "INGRID MAYARA MOUROSIN RUMY",
    "matricula": "501060902",
    "porcentagem": 0.4749794733202644,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/MAYARA%20MOUROSIN%20RUMY.png"
  },
  {
    "id": 9,
    "nome": "MAX JOHNATHAN GARCIA DUARTE",
    "matricula": "501070904",
    "porcentagem": 0.47438217146823225,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/MAX%20JOHNATHAN%20G.DUARTE.png"
  },
  {
    "id": 10,
    "nome": "PAULO EDSON AZEVEDO SOBRINHO",
    "matricula": "502080910",
    "porcentagem": 0.45952656075437165,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/PAULO%20EDSON%20A.%20SOBRINHO.png"
  },
  {
    "id": 11,
    "nome": "ALARY ALMIR GONCALVES",
    "matricula": "502010906",
    "porcentagem": 0.4545867105389831,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/ALARY%20ALMIR%20GONCALVES%20.png"
  },
  {
    "id": 12,
    "nome": "ADRIANO CESAR MARTINS DHESSICHK",
    "matricula": "501080901",
    "porcentagem": 0.4538852596870404,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/ADRIANO%20CESAR%20MARTINS%20DHESSICHK.png"
  },
  {
    "id": 13,
    "nome": "ERICK LOPES DE ARAUJO",
    "matricula": "501070903",
    "porcentagem": 0.45254802156160334,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/ERICK%20LOPES%20DE%20ARAUJO.png"
  },
  {
    "id": 14,
    "nome": "FRANCISCO GILBERTO FALASCO",
    "matricula": "501040903",
    "porcentagem": 0.45038332692368116,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/FRANCISCO%20G.%20FALASCO.png"
  },
  {
    "id": 15,
    "nome": "JESSICA BENZI DOS SANTOS",
    "matricula": "501080909",
    "porcentagem": 0.44991156589195186,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/JESSICA%20BENZI%20DOS%20SANTOS.png"
  },
  {
    "id": 16,
    "nome": "PAULO HENRIQUE SALLES",
    "matricula": "501070902",
    "porcentagem": 0.4496693197672596,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/PAULO%20HENRIQUE%20SALLES.png"
  },
  {
    "id": 17,
    "nome": "DANIELA JOSE DA SILVA ZANCA",
    "matricula": "501050903",
    "porcentagem": 0.44434792144563384,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/DANIELA%20JOSE%20DA%20SILVA%20ZANCA.png"
  },
  {
    "id": 18,
    "nome": "CAROLINE FERRAZ DE SOUZA",
    "matricula": "501010909",
    "porcentagem": 0.440025035491567,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/CAROLINE%20F.%20DE%20SOUZA.png"
  },
  {
    "id": 19,
    "nome": "ROBSON AUGUSTO DE JESUS JUNIOR",
    "matricula": "501030901",
    "porcentagem": 0.4388719849489453,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/ROBSON%20A.%20DE%20JESUS%20JUNIOR.png"
  },
  {
    "id": 20,
    "nome": "HELGA MIRANDA SLAMA REIS",
    "matricula": "501040906",
    "porcentagem": 0.43726121717410965,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/HELGA%20MIRANDA%20SLAMA%20REIS.png"
  },
  {
    "id": 21,
    "nome": "PRISCILA MECABO DA ROCHA",
    "matricula": "501010906",
    "porcentagem": 0.42773013765591833,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/PRISCILA%20M.DA%20ROCHA.png"
  },
  {
    "id": 22,
    "nome": "LOYSE AVILA GUIMARAES",
    "matricula": "502040904",
    "porcentagem": 0.42543323093228425,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/LOYSE%20AVILA%20GUIMARAES%20.png"
  },
  {
    "id": 23,
    "nome": "FERNANDA APARECIDA CORDEIRO",
    "matricula": "501060903",
    "porcentagem": 0.4238463651085675,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/FERNANDA%20A.%20CORDEIRO.png"
  },
  {
    "id": 24,
    "nome": "RODRIGO NOGUEIRA DE ABREU",
    "matricula": "501060906",
    "porcentagem": 0.4225290010811569,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/RODRIGO%20NOGUEIRA%20DE%20ABREU.png"
  },
  {
    "id": 25,
    "nome": "ALEXANDRE BARREIRO DIAS",
    "matricula": "501040905",
    "porcentagem": 0.41619473240942007,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/ALEXANDRE-BARREIRO-DIAS.png"
  },
  {
    "id": 26,
    "nome": "PATRICIA FONTES COELHO",
    "matricula": "501070907",
    "porcentagem": 0.4146759413903433,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/PATRICIA%20FONTES%20COELHO.png"
  },
  {
    "id": 27,
    "nome": "DANIEL WANDER DA SILVA",
    "matricula": "502080901",
    "porcentagem": 0.41147398287261394,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/DANIEL%20W.%20DA%20SILVA%20.png"
  },
  {
    "id": 28,
    "nome": "SCHELBY CRISTINE DUARTE",
    "matricula": "501020903",
    "porcentagem": 0.41002702523549694,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/SCHELBY%20C.%20DUARTE.png"
  },
  {
    "id": 29,
    "nome": "VICTOR DA CUNHA TERRA",
    "matricula": "502020902",
    "porcentagem": 0.4036605055051521,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/VICTOR%20DA%20CUNHA%20TERRA.png"
  },
  {
    "id": 30,
    "nome": "THIAGO ROBERTO CAMILO",
    "matricula": "501070908",
    "porcentagem": 0.4027914932210519,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/THIAGO%20ROBERTO%20CAMILO.png"
  },
  {
    "id": 31,
    "nome": "KARL CESAR DA ROSS SANTOS",
    "matricula": "501040908",
    "porcentagem": 0.4023250276730074,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/KARL-CESAR-DA-ROSS-SANTOS.png"
  },
  {
    "id": 32,
    "nome": "AQUILIS DE OLIVEIRA SOUZA",
    "matricula": "501080904",
    "porcentagem": 0.4021035348789045,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/AQUILIS%20DE%20OLIVEIRA%20SOUZA.png"
  },
  {
    "id": 33,
    "nome": "MARINA LINARES",
    "matricula": "502020906",
    "porcentagem": 0.3997421401946579,
    "img": "https://www.oscaraddera.com.br/assets/images/rp-sigma/MARINA%20LINARES.png"
  }
 ]
