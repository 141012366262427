<section id="tela">
  <div class="container d-flex">
    <div class="row">
      <div class="col  d-flex justify-center">
        <img src="../../../../assets/images/Boas_Praticas_Titulo.png" alt="GD alfa e delta addera" class="img-fluid mt-3" >
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="row d-flex text-center">
      <div class="col-10 col-sm-8 col-md-8 mb-15">

        <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/Q5KaAOW4Zxs"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
        <h1 class="text">Cidinha - São Paulo, SP</h1>
        <!--<img src="../../../assets/images/aspas.png" alt="" class="img-fluid">
        <p class="text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
          tincidunt ut laoreet dolore magna. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna. Lorem ipsum dolor sit amet, consectetuer
          adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna.</p>
          <img src="../../../assets/images/aspas.png" alt="" class="img-fluid left">-->

      </div>
    </div>
  </div>
</section>
