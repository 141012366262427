<section id="tela">
  <div class="container d-flex ">
    <div class="row">
      <div class="col d-flex justify-center">
        <img src="../../../../assets/images/Informes_Titulo.png" alt="GD alfa e delta addera" class="img-fluid mt-5" width="85%">
      </div>
    </div>
  </div>
</section>
<section class="mb-5">
  <div class="container d-flex">
    <div class="row">
      <div class="col-12 col-md-5 d-flex justify-center mb-3">
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_01.png" alt="GD alfa e delta addera" class="img-fluid" >
      </div>
      <div class="col-12 col-md-7 d-flex justify-end flex-wrap">
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_02.png" alt="GD alfa e delta addera" class="img-fluid" >
      </div>
    </div>
  </div>
</section>
<section class="mb-5">
  <div class="container d-flex">
    <div class="row">
      <div class="col-12 col-md-6 d-flex justify-end flex-wrap mb-3">
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_03.png" alt="GD alfa e delta addera" class="img-fluid mb-3" >
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_05.png" alt="GD alfa e delta addera" class="img-fluid" >
      </div>
      <div class="col-12 col-md-6 d-flex justify-center mb-3">
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_04.png" alt="GD alfa e delta addera" class="img-fluid" >
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container d-flex mb-3">
    <div class="row">
      <div class="col d-flex justify-center">
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_06.png" alt="GD alfa e delta addera" class="img-fluid" >
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container d-flex">
    <div class="row">
      <div class="col d-flex justify-center mb-15">
        <img src="../../assets/images/informes/498_projeto_addera_2023_JANEIRO_V2_Informe_07.png" alt="GD alfa e delta addera" class="img-fluid" >
      </div>
    </div>
  </div>
</section>
