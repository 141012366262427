export const GdAlfa = [
  {
    "id": 1,
    "nome": "SAMUEL CALDAS BATISTA MARQUES",
    "matricula": "304050000",
    "porcentagem": 0.5379013056696621,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_SAMUEL.png"
  },
  {
    "id": 2,
    "nome": "DANIEL RIBEIRO DA COSTA",
    "matricula": "303060000",
    "porcentagem": 0.5003480608698776,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_DANIEL.png"
  },
  {
    "id": 3,
    "nome": "LUCIANO PEREIRA BORGES JUNIOR",
    "matricula": "303040000",
    "porcentagem": 0.4845312940821848,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_LUCIANO.png"
  },
  {
    "id": 4,
    "nome": "THIAGO PEREIRA MONTE REY",
    "matricula": "305040000",
    "porcentagem": 0.47958738410178986,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_THIAGO.png"
  },
  {
    "id": 5,
    "nome": "MARCIO ALVES TEIXEIRA",
    "matricula": "304040000",
    "porcentagem": 0.45845491684314216,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_MARCIO.png"
  },
  {
    "id": 6,
    "nome": "PAULO ROBERTO LOUREIRO DE ALMEIDA ASSIS",
    "matricula": "301070000",
    "porcentagem": 0.43866021673380146,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta//GD_ALFA_DELTA_PAULO_ALMEIDA.png"
  },
  {
    "id": 7,
    "nome": "DANILO MACEDO LIMA",
    "matricula": "302080000",
    "porcentagem": 0.4373981936920285,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_DANILO.png"
  },
  {
    "id": 8,
    "nome": "WEMERSON TEOFILO DE OLIVEIRA",
    "matricula": "303050000",
    "porcentagem": 0.4221764237669418,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_WEMERSON.png"
  },
  {
    "id": 9,
    "nome": "PAULO SERGIO FEIJOLI JUNIOR",
    "matricula": "304080000",
    "porcentagem": 0.4109077955347087,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_PAULO_FEIJOLI.png"
  },
  {
    "id": 10,
    "nome": "RENATA PEREIRA DA SILVA",
    "matricula": "302060000",
    "porcentagem": 0.4035031840213717,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/GD_ALFA_DELTA_RENATA.png"
  },
  {
    "id": 11,
    "nome": "FELIPE MULLER",
    "matricula": "301030000",
    "porcentagem": 0.40224344217257896,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/FELIPE%20MULLER.png"
  },
  {
    "id": 12,
    "nome": "JOAO DIRCEU DE TOLEDO JUNIOR",
    "matricula": "302010000",
    "porcentagem": 0.4021874425336453,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-alfa-delta/JOAO%20DIRCEU%20DE%20TOLEDO%20JUNIOR.png"
  }
 ]
