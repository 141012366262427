export const Gdsigma = [
  {
    "id": 1,
    "nome": "NAICK FELIPE SANTOS CUNHA",
    "matricula": "501070000",
    "porcentagem": 0.44428826816932543,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-sigma/GD_SIGMA_NAICK.png"
  },
  {
    "id": 2,
    "nome": "LUCIANO LUCIO FERREIRA XAVIER",
    "matricula": "501080000",
    "porcentagem": 0.4376279048405191,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-sigma/GD_SIGMA_LUCIANO.png"
  },
  {
    "id": 3,
    "nome": "MARCIO PERESSIN",
    "matricula": "501030000",
    "porcentagem": 0.41020445525061866,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-sigma/GD_SIGMA_MARCIO.png"
  },
  {
    "id": 4,
    "nome": "RENATA MARTINO DE CARVALHO LEAL",
    "matricula": "502050000",
    "porcentagem": 0.39997125833771435,
    "img": "https://www.oscaraddera.com.br/assets/images/gd-sigma/RENATA%20MARTINO%20DE%20CARVALHO%20LEAL.png"
  }
 ]
